
import { defineComponent, ref, computed } from "vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import { useRouter } from "vue-router";
import useBreakpoint from "@/lib/compositional-logic/useBreakpoint";
import DropdownTrigger from "@/components/atomics/dropdown/DropdownTrigger.vue";
import { RepositoryFactory, AuthRepository } from "@/lib/https";
import store from "@/store";

export default defineComponent({
  name: "HomeHeader",
  components: {
    TextButton,
    FlatButton,
    DropdownTrigger,
  },
  props: {
    header: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:header"],
  setup() {
    const router = useRouter();
    const { isMobile } = useBreakpoint();

    const { logout } =
      RepositoryFactory.getRepository<AuthRepository>(AuthRepository);

    const dropdownTrigger = ref<InstanceType<typeof DropdownTrigger>>();
    const accountInfo = computed(() => store.state.account);

    const onLogout = async () => {
      await logout();
      localStorage.clear();
      window.location.href = "/login";
    };

    const navigationToAccount = () => {
      router.push({ name: "MyAccount" });
      if (dropdownTrigger.value) dropdownTrigger.value.isDropdownOpen = false;
    };

    return {
      onLogout,
      navigationToAccount,
      dropdownTrigger,
      accountInfo,
      isMobile,
    };
  },
});
