import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fa2dcc52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "after-login-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheSideBarAdmin = _resolveComponent("TheSideBarAdmin")!
  const _component_AdminHeader = _resolveComponent("AdminHeader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fadeHeight" }, {
      default: _withCtx(() => [
        (!_ctx.isMobile || _ctx.isShowSideBar)
          ? (_openBlock(), _createBlock(_component_TheSideBarAdmin, {
              key: 0,
              class: "sidebar",
              onCloseSidebar: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowSideBar = false))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_AdminHeader, {
      header: _ctx.isShowSideBar,
      "onUpdate:header": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isShowSideBar) = $event))
    }, null, 8, ["header"]),
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.fullPath.includes('template/') ? null : _ctx.$route.fullPath
    }))
  ]))
}