
import { defineComponent } from "vue";
import useMenuList from "@/lib/compositional-logic/useMenuList";

export default defineComponent({
  name: "TheSideBarAdmin",
  emits: ["close-sidebar"],
  setup() {
    const { menuList } = useMenuList();

    return {
      menuList,
    };
  },
});
