
import { defineComponent, ref } from "vue";
import TheSideBarAdmin from "@/components/organisms/TheSideBarAdmin.vue";
import AdminHeader from "@/components/organisms/AfterLoginHeaderAdmin.vue";
import useBreakpoint from "@/lib/compositional-logic/useBreakpoint";

export default defineComponent({
  name: "AfterLoginAdminLayout",
  components: {
    TheSideBarAdmin,
    AdminHeader,
  },
  setup() {
    const isShowSideBar = ref(false);
    const { isMobile } = useBreakpoint();
    return {
      isShowSideBar,
      isMobile,
    };
  },
});
